.table {
  @apply min-w-full;
  @apply divide-y;
  @apply divide-gray-200;
}

.table thead {
  @apply border-t;
  @apply border-b;
  @apply bg-primary-50;
  @apply border-primary-lighter;
}

.table thead tr th {
  @apply px-5;
  @apply py-4;
  @apply text-left;
  @apply text-xs;
  @apply font-semibold;
  @apply text-gray-500;
  @apply tracking-wider;
}

.table thead tr th .btn_th-sort {
  @apply group;
  @apply ml-1.5;
  @apply rounded-sm;
  @apply inline-flex;
  @apply opacity-40;
  @apply focus:opacity-100;
  @apply focus:outline-none;
  @apply focus:ring-2;
  @apply focus:ring-offset-2;
  @apply focus:ring-primary;
}

.table thead tr th .icon_th-sort {
  @apply w-3.5;
  @apply h-3.5;
  @apply text-black;
}

.table thead tr th.th-action {
  @apply w-0.5;
  @apply text-center;
}

.table thead tr th.th-action:first-child,
.table tbody tr td.tb-action:first-child {
  @apply pr-0;
}

.table tbody {
  @apply bg-white;
  @apply divide-y;
  @apply divide-gray-200;
}

.table tbody tr td {
  @apply px-5;
  @apply py-3;
  @apply text-sm;
  @apply text-gray-900;
  @apply break-words;
}

.table.table-clickable tbody tr:hover {
  @apply bg-gray-50;
  @apply cursor-pointer;
}

.table.table-dropdown thead {
  @apply border-t-0;
}

.table.table-dropdown thead tr th,
.table.table-dropdown tbody tr td {
  @apply px-3;
  @apply py-2;
  @apply text-xs;
  @apply whitespace-nowrap;
}

.table.table-dropdown tbody tr:hover {
  @apply cursor-pointer;
}

.table.table-dropdown tbody tr.selected-row {
  @apply bg-secondary;
}

.table.table-dropdown tbody tr.selected-row td {
  @apply text-white;
}

.table tbody tr.td-align-top td {
  vertical-align: top;
}

.table tbody tr.td-align-top .td-cell {
  @apply flex;
  @apply flex-wrap;
  @apply items-center;
  min-height: 2.358rem;
}

@screen sm {
  .table tbody tr.td-align-top .td-cell {
    min-height: 2.334rem;
  }
}

@screen 2xl {
  .table tbody tr.td-align-top .td-cell {
    min-height: 2.375rem;
  }
}

.tippy-box {
  @apply bg-blue-200;
  box-shadow: 0 0 5px rgb(0 0 0 / 5%);
}

.tippy-box[data-placement^='top'] > .tippy-arrow::before {
  border-top-color: theme('colors.blue.200');
}
.tippy-box[data-placement^='bottom']
  > .tippy-arrow::before {
  border-bottom-color: theme('colors.blue.200');
}
.tippy-box[data-placement^='left']
  > .tippy-arrow::before {
  border-left-color: theme('colors.blue.200');
}
.tippy-box[data-placement^='right']
  > .tippy-arrow::before {
  border-right-color: theme('colors.blue.200');
}
.tippy-box > .tippy-svg-arrow {
  fill: theme('colors.blue.200');
}

.tippy-content {
  @apply px-2;
  @apply py-1;
  @apply font-medium;
  @apply text-blue-700;
}

.tippy-box[data-theme~='dropdown'] {
  @apply border;
  @apply border-opacity-10;
  @apply bg-white;
  box-shadow: 0 0px 5px rgb(0 0 0 / 8%), 0 0px 20px rgb(0 0 0 / 10%);
}

.tippy-box[data-theme~='dropdown'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: theme('colors.white');
}
.tippy-box[data-theme~='dropdown'][data-placement^='bottom']
  > .tippy-arrow::before {
  border-bottom-color: theme('colors.white');
}
.tippy-box[data-theme~='dropdown'][data-placement^='left']
  > .tippy-arrow::before {
  border-left-color: theme('colors.white');
}
.tippy-box[data-theme~='dropdown'][data-placement^='right']
  > .tippy-arrow::before {
  border-right-color: theme('colors.white');
}
.tippy-box[data-theme~='dropdown'] > .tippy-svg-arrow {
  fill: theme('colors.white');
}

div[data-tippy-root] {
  @apply pointer-events-auto !important;
}

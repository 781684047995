.vdp-input {
  @apply border;
  @apply border-gray-200;
  @apply appearance-none;
  @apply block;
  @apply w-full;
  @apply pt-2;
  @apply pr-3;
  @apply pb-2;
  @apply pl-3;
  @apply text-sm;
  @apply rounded;
  @apply shadow-none;
  @apply placeholder-gray-400;
}

.vdp-input:disabled {
  @apply bg-gray-50;
  @apply cursor-not-allowed;
}

.vdp-input:focus {
  @apply outline-none;
  @apply ring-primary;
  @apply border-primary;
  @apply shadow-none;
}

.v3dp__popout {
  @apply mt-1;
  @apply mb-1;
}

.switch {
  @apply w-14;
  @apply h-7;
  @apply relative;
  @apply inline-flex;
  @apply items-center;
  @apply bg-gray-300;
  @apply rounded-full;
}

.switch:focus {
  @apply outline-none;
  @apply focus:ring-2 focus:ring-offset-2 focus:ring-primary;
}

.switch.enabled {
  @apply bg-primary;
}

.switch .switch-dot {
  @apply inline-block;
  @apply w-5;
  @apply h-5;
  @apply bg-white;
  @apply rounded-full;
  @apply transform;
  @apply translate-x-1;
}

.switch.enabled .switch-dot {
  @apply translate-x-8;
}

.tox-statusbar__branding,
.tox-notification--warning {
  @apply hidden !important;
}

textarea[id^='tiny-vue'] {
  @apply hidden !important;
}

.tinymce-wrapper .tox.tox-tinymce {
  min-height: calc(100vh - 230px) !important;
}

.tox .tox-statusbar__resize-handle {
  cursor: ns-resize !important;
}

@screen sm {
    .sidebar-shadow {
        -webkit-box-shadow: 40px 0px 40px -40px rgb(0 0 0 / 20%) inset;
        -moz-box-shadow: 40px 0px 40px -40px rgb(0 0 0 / 20%) inset;
        box-shadow: 40px 0px 40px -40px rgb(0 0 0 / 20%) inset;
    }
}

@screen md {
    .scroll-container {
        @apply overflow-y-auto;
    }
}

.overflow-y-auto-custom {
    overflow-y: auto;
    max-height: calc(100vh - 500px);
}

.z-in1 {
    z-index: -1;
}

div.jsoneditor-menu a.jsoneditor-poweredBy {
    display: none !important;
}

.gatwayFixedFooter {
    width: 100%;
    position: inherit;
}

@media (min-width: 576px) {
    .gatwayFixedFooter {
        width: 76rem;
        max-width: calc(100vw - 2rem);
    }
}

@media (min-width: 640px) {
    .gatwayFixedFooter {
        max-width: calc(100vw - 3rem);
    }
}

@media (min-width: 768px) {
    .gatwayFixedFooter {
        max-width: calc(100vw - 4rem);
    }
}

@media (min-width: 1024px) {
    .gatwayFixedFooter {
        max-width: calc(100vw - 20rem);
        position: fixed;
    }
}

@media (min-width: 1024px) {
    .scrollBody {
        /*  height: calc(100vh - 509px); */
    }
}

/* Works for Chrome, Safari, Edge, Opera */
.inputType-number input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
   }

 /* Works for Firefox */
.inputType-number input[type="number"] {
     -moz-appearance: textfield;
   }

.pairLoading Button{
    display: none;
}

.pair_linksvg svg{
    stroke-width: 2px;
}
.vld-icon svg {
  stroke: theme('colors.primary');
  @apply cursor-wait;
}

.vld-overlay .vld-background {
  @apply bg-black;
  @apply opacity-30;
  @apply cursor-wait;
}

.DarkWhiteLoading .vld-background {
	background-color: #fff !important;
	opacity: 0.8 !important;
}

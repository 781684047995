:root {
  --tw-bg-opacity: 1;

  /* Colors */
  --color-primary: 0, 26, 102;
  --color-primary-light: 96, 112, 159;
  --color-primary-lighter :  223, 226, 236;
  --color-primary-lightest :  239, 240, 245;
  --color-primary-50 :  247, 248, 250;
  --color-secondary :  128, 141, 179;
  --theme-secondary :  46, 67, 128;

  /* vue3-datepicker */
  --vdp-selected-bg-color: theme('colors.primary');
  --vdp-hover-bg-color: theme('colors.primary');
  --vdp-heading-weight: 600;
}

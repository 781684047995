/* Primary tabs */

.tabs-wrapper {
  @apply mb-4;
  @apply overflow-x-auto;
  @apply overflow-y-hidden;
}

.tabs {
  @apply border-b;
  @apply border-primary;
  @apply relative;
  @apply z-0;
}

.tab-item {
  bottom: -1px;
  @apply relative;
  @apply border-b;
  @apply border-primary;
  @apply p-4;
  @apply table-cell;
  @apply bg-white;
  @apply text-gray-500;
  @apply font-medium;
  @apply text-center;
  @apply text-sm;
  @apply whitespace-nowrap;
}

.tab-item:hover {
  @apply bg-primary-lightest;
  @apply text-primary;
}

.tab-item:focus {
  @apply outline-none;
  @apply underline;
  @apply text-black;
  @apply text-opacity-80;
}

.tab-item.active {
  @apply border;
  @apply border-b-0;
  @apply border-primary;
  @apply bg-primary-lightest;
  @apply text-primary;
}

.tab-container {
  @apply mb-4;
}

/* Secondary tabs */

.secondary-tab-item {
  @apply border-b-2;
  @apply border-transparent;
  @apply whitespace-nowrap;
  @apply px-1;
  @apply py-3;
  @apply text-gray-500;
  @apply font-medium;
  @apply text-sm;
}

.secondary-tab-item:hover {
  @apply border-primary;
  @apply text-black;
  @apply text-opacity-80;
}

.secondary-tab-item:focus {
  @apply outline-none;
  @apply underline;
  @apply text-black;
  @apply text-opacity-80;
}

.secondary-tab-item.active {
  @apply border-primary;
  @apply text-primary;
}

.secondary-tab-item.active:hover {
  @apply text-primary;
  @apply border-primary;
}

/* Steps tab wizard */

.tab-wizard-wrapper {
  @apply overflow-hidden;
  @apply mb-4;
}

.tab-wizard {
  @apply flex;
  @apply border-b;
  @apply border-primary;
  @apply overflow-x-auto;
  @apply overflow-y-hidden;
}

.tab-wizard-item {
  @apply relative;
  @apply px-7;
  @apply py-4;
  @apply flex-1;
  @apply bg-white;
  @apply text-gray-500;
  @apply font-medium;
  @apply text-center;
  @apply text-sm;
  @apply whitespace-nowrap;
}

.tab-wizard-item:hover {
  @apply text-primary;
  @apply underline;
}

.tab-wizard-item:focus {
  @apply underline;
  @apply outline-none;
}

.tab-wizard-item.active {
  @apply bg-primary-lightest;
  @apply text-primary;
}

.tab-wizard-item:before {
  content: "";
  @apply absolute;
  @apply inline-block;
  @apply top-0;
  @apply left-0;
  @apply w-0;
  @apply h-0;
  @apply border-solid;
  border-width: 1.64rem;
  border-color: transparent transparent transparent theme('colors.white');
}

.tab-wizard .active:first-child:before,
.tab-wizard-item.active + .tab-wizard-item:before {
  border-color: transparent transparent transparent theme('colors.primary-lightest');
}
